export const brands = [
  {
    id: 1,
    title: "Samsung",
    url: "https://www.samsung.com/in/",
    img: "/images/brands/samsung.png",
    products: [
      { name: "LED", url: "https://www.samsung.com/in/tvs/" },
      {
        name: "AC",
        url: "https://www.samsung.com/in/air-conditioners/split-ac/",
      },
      {
        name: "Washing Machine",
        url:
          "https://www.samsung.com/in/washers-and-dryers/all-washers-and-dryers/?front-loading",
      },
      {
        name: "Refrigerator",
        url: "https://www.samsung.com/in/refrigerators/",
      },
      { name: "Microwave", url: "samsung.com/in/cooking-appliances/" },
    ],
  },

  {
    id: 2,
    title: "Panasonic",
    url: "https://www.panasonic.com/in/",
    img: "/images/brands/panasonic.jpg",
    products: [
      {
        name: "LED",
        url:
          "https://www.panasonic.com/in/consumer/home-entertainment/televisions/",
      },
      {
        name: "AC",
        url:
          "https://www.panasonic.com/in/consumer/air-conditioners/split-ac.html",
      },
      {
        name: "Washing Machine",
        url:
          "https://www.panasonic.com/in/consumer/home-appliances/washing-machines/fully-automatic-Front-Load.html",
      },
      {
        name: "Refrigerator",
        url:
          "https://www.panasonic.com/in/consumer/home-appliances/refrigerators/multi-door.html",
      },
      {
        name: "Microwave",
        url:
          "https://www.panasonic.com/in/consumer/home-appliances/microwave-ovens/solo.html",
      },
    ],
  },
  {
    id: 3,
    title: "Sony",
    url: "https://www.sony.co.in/",
    img: "/images/brands/sony.jpg",
    products: [
      {
        name: "LED",
        url: "https://www.sony.co.in/electronics/tv/t/televisions",
      },
      { name: "Soundbar", url: "https://www.sony.co.in/electronics/audio" },
    ],
  },
  {
    id: 4,
    title: "Daikin",
    url: "https://www.sony.co.in/",
    img: "/images/brands/daikin.jpg",
    products: [
      { name: "AC", url: "https://www.daikinindia.com/product-category/split" },
    ],
  },
  {
    id: 5,
    title: "VU",
    url: "https://www.lg.com/in",
    img: "/images/brands/vu.jpg",
    products: [{ name: "LED", url: "https://www.vutvs.com/products" }],
  },
  {
    id: 6,
    title: "Mitsubishi Heavy",
    url: "https://www.sony.co.in/",
    img: "/images/brands/mitHeavy.jpg",
    products: [{ name: "AC", url: "https://www.iaplgroup.com" }],
  },
  {
    id: 7,
    title: "TCL",
    url: "https://www.tcl.com/in/en.html",
    img: "/images/brands/tcl.jpg",
    products: [
      {
        name: "LED",
        url: "https://www.tcl.com/in/en/products.allproducts.html",
      },
    ],
  },
  {
    id: 8,
    title: "Mitsubishi Electric",
    url: "https://www.sony.co.in/",
    img: "/images/brands/mitElectric.png",
    products: [
      {
        name: "AC",
        url:
          "https://mitsubishielectric.in/products/air-conditioning-systems/split-air-conditioners/inverter-winter-and-summer-series",
      },
    ],
  },
  {
    id: 9,
    title: "Bosch",
    url: "https://www.bosch.in/",
    img: "/images/brands/bosch.jpg",
    products: [
      {
        name: "Washing Machine",
        url:
          "https://www.bosch-home.in/products/washers-dryers/washing-machines",
      },
      {
        name: "Refrigerator",
        url: "https://www.bosch-home.in/products/refrigerator/fridge-freezers",
      },
    ],
  },
  {
    id: 10,
    title: "IFB",
    url: "https://www.tcl.com/in/en.html",
    img: "/images/brands/ifb.png",
    products: [
      {
        name: "Washing Machine",
        url:
          "https://www.ifbappliances.com/products/laundry/washing-machine/front-loader",
      },
      {
        name: "Dish Washer",
        url: "https://www.ifbappliances.com/products/kitchen/dishwashers",
      },
      { name: "Refrigerator", url: "" },
    ],
  },

  {
    id: 11,
    title: "Siemens",
    url: "https://www.panasonic.com/in/",
    img: "/images/brands/siemens.jpg",
    products: [
      {
        name: "Washing Machine",
        url:
          "https://www.siemens-home.bsh-group.in/products-list/laundry-care/washing-machines",
      },
      {
        name: "Washer Dryer",
        url:
          "https://www.siemens-home.bsh-group.in/products-list/laundry-care/washer-dryer-machines",
      },
      {
        name: "Refrigerator",
        url:
          "https://www.siemens-home.bsh-group.in/appliances/cooling/builtin-fridges-freezers",
      },
    ],
  },
  {
    id: 12,
    title: "Sharp",
    url: "https://www.bosch.in/",
    img: "/images/brands/sharp.png",
    products: [
      { name: "AC", url: "https://th.sharp/en/products/air-conditioner" },
    ],
  },
  {
    id: 13,
    title: "Lloyd",
    url: "https://www.panasonic.com/in/",
    img: "/images/brands/lloyd.png",
    products: [
      { name: "AC", url: "https://www.mylloyd.com/air-conditioners/split-ac" },
    ],
  },
  {
    id: 14,
    title: "Amstrad",
    url: "https://www.sony.co.in/",
    img: "/images/brands/amstard.png",
    products: [
      {
        name: "AC",
        url:
          "https://amstradworld.com/product-category/air-conditioner/split-ac/",
      },
    ],
  },
  {
    id: 15,
    title: "Voltas",
    url: "https://www.panasonic.com/in/",
    img: "/images/brands/voltas.jpg",
    products: [
      { name: "AC", url: "https://www.myvoltas.com/air-conditioners/split-ac" },
      {
        name: "Refrigerator",
        url: "https://www.myvoltas.com/commercial-refrigerators",
      },
    ],
  },
  {
    id: 16,
    title: "Whirlpool",
    url: "https://www.whirlpoolindia.com/",
    img: "/images/brands/whirlpool.jpg",
    products: [
      {
        name: "AC",
        url: "https://www.whirlpoolindia.com/air-conditioners#gref",
      },
      {
        name: "Refrigerator",
        url:
          "https://www.whirlpoolindia.com/refrigerators/all-refrigerators.html",
      },
    ],
  },
];
