import React from "react";
import "./Services.css";
import Fade from "react-reveal/Fade";

const Services = () => {
  return (
    <Fade left cascade>
      <div className="root services-container">
        <div className="container">
          <div className="services-child-image">
            <img
              src="images/services/servicesFirstImage.jpg"
              className="first-image"
              alt="Pintu Airtronics"
            />
          </div>
          <div className="float-child-info">
            <p>
              Pintu Airtronics, we are the power that shines you. We have been
              in the electronics business since last 20 bright years, dealing
              with each and every type of consumer electronic products.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="services-child-image">
            <img
              src="images/services/acRepair.jpg"
              className="first-image"
              alt="Repair Service"
            />
          </div>
          <div className="float-child-info">
            <p>
              We are here with our professional technicians to provide you with
              all types of fittings, installing, uninstalling, small or major
              fixings of air conditioners, LED tv etc.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="services-child-image">
            <img
              src="images/services/vrv.png"
              className="first-image  third-image"
              alt="VRV Service"
            />
          </div>
          <div className="float-child-info third-image-info">
            <p>
              We also provide VRV systems in our air conditioners making it
              better, healthier, safer for you and our environment, we have
              almost done more than 20 projects of VRV system air conditioners
              making an empowered tomorrow.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="services-child-image">
            <img
              src="images/services/companies.jpg"
              className="first-image"
              alt="Companies"
            />
          </div>
          <div className="float-child-info">
            <p>
              We trade in with the best and the most trusted companies for all
              of our products. We believe in innovation in every step we take.
            </p>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Services;
