import React from "react";
import "./Home.css";
import AboutCard from "./AboutCard/AboutCard";
import Brands from "./Brands/Brands";
import Footer from "./Footer/Footer";

const Home = () => {
  return (
    <div className="root">
      <AboutCard />
      <Brands />
      <Footer />
    </div>
  );
};

export default Home;
