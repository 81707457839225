import washingMachine from "@iconify-icons/mdi/washing-machine";
import dishwasherIcon from "@iconify-icons/mdi/dishwasher";
import fridgeIcon from "@iconify-icons/mdi/fridge";
import speakerWireless from "@iconify-icons/mdi/speaker-wireless";
import baselineAcUnit from "@iconify-icons/ic/baseline-ac-unit";
import roundMicrowave from "@iconify-icons/ic/round-microwave";
import tumbleDryer from "@iconify-icons/mdi/tumble-dryer";
import tvSharp from "@iconify-icons/ion/tv-sharp";

export const productData = [
  {
    id: 1,
    class: washingMachine,
    name: "Washing Machine",
  },
  {
    id: 2,
    class: baselineAcUnit,
    name: "AC",
  },
  {
    id: 3,
    class: roundMicrowave,
    name: "Microwave",
  },
  {
    id: 4,
    class: dishwasherIcon,
    name: "Dish Washer",
  },
  {
    id: 5,
    class: tumbleDryer,
    name: "Washer Dryer",
  },
  {
    id: 6,
    class: fridgeIcon,
    name: "Refrigerator",
  },
  {
    id: 7,
    class: tvSharp,
    name: "LED",
  },
  {
    id: 8,
    class: speakerWireless,
    name: "Soundbar",
  },
];
