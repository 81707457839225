import React, { useState } from "react";
import "./Brands.css";
import { brands as brandData } from "./BrandsData";
import Pagination from "@material-ui/lab/Pagination";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import Products from "../Products/Products";

const Brands = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    startingIndex: 0,
  });
  const [brandImagesFlag, setBrandImageFlag] = useState(false);
  const [products, setProducts] = useState([]);
  const [brandName, setBrandName] = useState("");

  const handlePage = (e, val) => {
    const newPage = val;
    const newIndex = (val - 1) * 4;
    setPagination({ currentPage: newPage, startingIndex: newIndex });
    setBrandImageFlag(false);
  };

  const handleImageClick = async (products, brand) => {
    await setBrandName(brand);
    await setProducts(products);
    await setBrandImageFlag(false);
    await setBrandImageFlag(true);
  };

  const closeProducts = () => {
    setBrandImageFlag(false);
  };

  return (
    <div className="brands">
      <h2 className="brands-header">Brands we deal With</h2>
      <div className="wrapper">
        {brandData.map((brand) => {
          const index = brandData.indexOf(brand);
          if (
            index >= pagination.startingIndex &&
            index < pagination.startingIndex + 4
          ) {
            return (
              <Flip left key={brand.title}>
                <div className="wrapper-div">
                  <img
                    src={brand.img}
                    alt={brand.title}
                    className="brand-images"
                    onClick={() =>
                      handleImageClick(brand.products, brand.title)
                    }
                  />
                </div>
              </Flip>
            );
          }
          return null;
        })}
      </div>
      <Fade collapse when={brandImagesFlag}>
        <Products
          products={products}
          brandImagesFlag={brandImagesFlag}
          closeProducts={closeProducts}
          brand={brandName}
        />
      </Fade>
      <div>
        <Pagination
          className="pagination"
          count={4}
          onChange={handlePage}
          page={pagination.currentPage}
        />
      </div>
      <p>Click On Any Brand to check Products</p>
    </div>
  );
};

export default Brands;
