import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div>
      <header className="p-header">
        <div className="p-header-inner">
          <div className="p-header-name">
            <img
              src="images/pintu-airtronics-shop-name.png"
              className="p-header-name"
              alt="Shop Name"
            />
          </div>
          <div className="p-header-logo">
            <img
              src="images/pintu-airtronics-logo.png"
              className="p-logo-image"
              alt="Shop Logo"
            />
          </div>
        </div>
      </header>
      <div className="p-header-bottom">
        <div className="p-header-bottom-container">
          <ul className="tabs">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/services">Our Services</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
