import "./App.css";
import Header from "./components/Header/Header";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./components/Home/Home";
import Services from "./components/Services/Services";
import ContactUs from "./components/ContactUs/ContactUs";

const App = () => {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route path="/services" exact>
          <Services />
        </Route>
        <Route path="/contact" exact>
          <ContactUs />
        </Route>
        <Route path="/" exact>
          <Home />
        </Route>
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default App;
