import React from "react";
import "./Products.css";
import { productData } from "./ProductsData";
import { Icon } from "@iconify/react";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Products = (props) => {
  return (
    <div
      style={{ display: "inline-flex", paddingTop: "20px" }}
      className="product-outer-container"
    >
      <div>
        <p style={{ fontSize: "20px" }}>
          Product Categories of <strong>{props.brand}</strong>
        </p>
        <ul className="ul-wrapper">
          {props.products.map((product) => {
            let iconData = "";
            iconData = productData.find(({ name }) => name === product.name);
            return (
              <div
                className="product-container products-div"
                onClick={() => window.open(product.url)}
                key={product.name}
              >
                <li id={product} key={product} className="product-item">
                  <Icon
                    className="icon-class"
                    icon={iconData ? iconData["class"] : ""}
                  />
                  <div>
                    <p className="product-label">
                      {iconData ? iconData["name"] : ""}
                    </p>
                  </div>
                </li>
              </div>
            );
          })}
        </ul>
      </div>
      <FontAwesomeIcon
        className="hide-button"
        onClick={() => props.closeProducts()}
        icon={faWindowClose}
        size="1x"
      />
    </div>
  );
};

export default Products;
