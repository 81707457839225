import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import "./ContactUs.css";
import contactSvg from "./contact.svg";
import Grid from "@material-ui/core/Grid";
import emailjs from "emailjs-com";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import LightSpeed from "react-reveal/LightSpeed";

const ContactUs = () => {
  const [error, setError] = useState({
    name: false,
    contactNo: false,
    nameFilled: false,
    contactFilled: false,
  });
  const [details, setDetails] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const [upload, setUpload] = useState({ success: false, error: false });

  const pattern = {
    name: /^([a-zA-z ]+)$/,
    // email: /^([a-zA-Z\d\.-_]+)@([a-z\d-]+)\.([a-z]{2,8})$/,
    contactNo: /^[+]?[0-9]{10}[0-9 ]*$/,
    // contactNo: /^[- +()]*[0-9][- +()0-9]*$/,
  };

  const uploadSuccess = async () => {
    await setUpload({ ...upload, success: true });

    setTimeout(() => {
      setUpload({ ...upload, success: false });
    }, 5000);
  };

  const uploadFail = async () => {
    await setUpload({ ...upload, error: true });

    setTimeout(() => {
      setUpload({ ...upload, error: false });
    }, 5000);
  };

  const handleSubmit = async () => {
    const data = [
      details.name,
      details.email,
      details.contact,
      details.message,
      new Date().toLocaleString(),
    ];
    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/pintuairtronics/google_sheets/EpIjCNeoPnZOllQz?tabId=Sheet1",
        {
          method: "post",
          body: JSON.stringify([data]),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      console.log("Success:", JSON.stringify(json));
      if (json.message !== "Successfully Inserted") {
        uploadFail();
      } else {
        await setDetails({
          name: "",
          email: "",
          contact: "",
          message: "",
        });
        uploadSuccess();
      }
    } catch (error) {
      console.log(error);
      uploadFail();
    }
  };

  const sendEmail = () => {
    emailjs
      .send(
        "pintuAirtronics",
        "template_xh211hl",
        details,
        "user_HDETMs0B0D9Gr3nb3sqyi"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const inputValidation = () => {
    setError({
      name: false,
      contactNo: false,
      nameFilled: false,
      contactFilled: false,
    });

    const isNameValid = pattern.name.test(details.name) || details.name === "";
    const isContactValid =
      pattern.contactNo.test(details.contact) || details.contact === "";

    const isNameFilled = details.name !== "";
    const isContactFilled = details.contact !== "";
    const isValid =
      isNameValid && isContactValid && isNameFilled && isContactFilled;

    if (isValid) {
      handleSubmit();
      sendEmail();
    } else {
      const errorObj = {
        name: !isNameValid,
        contactNo: !isContactValid,
        nameFilled: !isNameFilled,
        contactFilled: !isContactFilled,
      };
      setError(errorObj);
    }
  };

  const handleInput = (type, e) => {
    const value = e.target.value;

    switch (type) {
      case "name":
        setDetails({ ...details, name: value });
        break;
      case "email":
        setDetails({ ...details, email: value });
        break;
      case "contact":
        setDetails({ ...details, contact: value });
        break;
      case "msg":
        setDetails({ ...details, message: value });
        break;
      default:
        break;
    }
  };

  return (
    <div className="main-container">
      <Bounce left cascade>
        <div className="contact-us-container form-container">
          <div className="talk-to-us">
            <h1>Talk to Us</h1>
            <p>We are here for all your queries!</p>
          </div>
          <div className="texfield-contactUs">
            <Grid className="t-grid">
              <div className="t-name">
                <TextField
                  className="t-name"
                  margin="normal"
                  label="Name"
                  value={details.name}
                  required
                  variant="outlined"
                  color="primary"
                  onChange={(e) => handleInput("name", e)}
                  error={error.name || error.nameFilled}
                  helperText={
                    (error.name && "Please fill Valid Name") ||
                    (error.nameFilled && "Please fill your Name")
                  }
                />
              </div>
              <div className="t-contact">
                <TextField
                  className="t-contact"
                  margin="normal"
                  label="Contact No"
                  value={details.contact}
                  variant="outlined"
                  color="primary"
                  required
                  onChange={(e) => handleInput("contact", e)}
                  error={error.contactNo || error.contactFilled}
                  helperText={
                    (error.contactNo && "Please fill Valid Contact No") ||
                    (error.contactFilled && "Please fill your Contact No")
                  }
                />
              </div>
              <div className="t-email">
                <TextField
                  className="t-email"
                  margin="normal"
                  label="Email"
                  value={details.email}
                  variant="outlined"
                  color="primary"
                  onChange={(e) => handleInput("email", e)}
                />
              </div>
              <div className="textfield-message">
                <TextField
                  className="textfield-message"
                  label="Message"
                  value={details.message}
                  variant="outlined"
                  color="primary"
                  multiline
                  rows={10}
                  fullWidth
                  onChange={(e) => handleInput("msg", e)}
                />
              </div>
            </Grid>

            <div className="send-button">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={inputValidation}
              >
                Send
              </Button>
              <LightSpeed left when={upload.success}>
                <p>Successfully Submitted</p>
              </LightSpeed>
              <LightSpeed left when={upload.error}>
                <p>Some error occurred. Please try again!</p>
              </LightSpeed>
            </div>
          </div>
        </div>
      </Bounce>
      <Bounce right>
        <div className="contact-us-container">
          <img
            height={400}
            width={350}
            src={contactSvg}
            alt="Contact Us Icon"
            className="contact-us-icon"
          />
        </div>
      </Bounce>

      <div className="address-container">
        <Fade left cascade>
          <div>
            <img
              src="/images/digitalCard.jpeg"
              alt="Digital Card"
              style={{ marginTop: "10px" }}
              className="digital-card card"
            />
            <br />
          </div>
          <div className="shop-info">
            <div>
              <h2>Contact Info</h2>
              <p>
                Mobile no: 76667-64446
                <br />
                Landline: 2590-6060
                <br />
                Email: pintuairtronics@gmail.com
              </p>
            </div>
          </div>
          <div className="shop-info">
            <div>
              <h2>Address</h2>
              <p>
                Shop No 08, Mehul Cinema,
                <br />
                Pandit Jawaharlal Nehru Road,
                <br />
                Near Mehul Cinema Mulund West, <br /> Mumbai - 400080
              </p>
              <br />
            </div>
          </div>
          <div className="shop-map">
            <iframe
              title="map"
              width="330"
              height="180"
              frameBorder="1"
              style={{ border: 1, margin: 10 }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.6761311176856!2d72.94674031490256!3d19.165648987036583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b8f5ccc3026b%3A0xfea84513bf0762fd!2sPintu%20Airtronics!5e0!3m2!1sen!2sin!4v1612876858044!5m2!1sen!2sin"
              allowFullScreen
              className="card"
            ></iframe>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default ContactUs;
