import React from "react";
import "./Footer.css";
import Fade from "react-reveal/Fade";
import { Icon } from "@iconify/react";
import facebookIcon from "@iconify-icons/logos/facebook";
import linkedinIcon from "@iconify-icons/logos/linkedin-icon";

const Footer = () => {
  return (
    <Fade right cascade>
      <div className="footer-outer-div">
        <div className="footer-header">
          <div className="working-hours footer-content">
            <h2>Working Hours</h2>
            <p>Monday-Saturday: 10:00 am - 09:00 pm</p>
          </div>
          <div className="location footer-content">
            <h2>Location</h2>
            <p>
              Shop No 08, Mehul Cinema,
              <br />
              Pandit Jawaharlal Nehru Road,
              <br />
              Near Mehul Cinema Mulund West, <br /> Mumbai - 400080
            </p>
          </div>
          <div className="location footer-content">
            <h2>Our Other Entitites</h2>
            <p
              className="entities"
              onClick={() =>
                window.open(
                  "https://www.google.com/maps/place/Mehul+Talkies/@19.1657859,72.9487753,19z/data=!3m1!4b1!4m5!3m4!1s0x3be7c8ffc73f0a23:0xedc7104b6f7e59f6!8m2!3d19.1657846!4d72.9493225"
                )
              }
            >
              TV Tronics{" "}
            </p>
            <p
              className="entities"
              onClick={() =>
                window.open(
                  "https://www.google.com/maps/place/Sahar+Rd,+Mumbai,+Maharashtra+400047/@19.1086673,72.8543277,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7c849056b4e8d:0x38674b129d8918b!8m2!3d19.1086673!4d72.8565164"
                )
              }
            >
              {" "}
              NFT LLP{" "}
            </p>
            <p
              className="entities"
              onClick={() =>
                window.open(
                  "https://www.google.com/maps/place/Estilo+Electronics/@19.0767887,72.9013953,17z/data=!3m1!4b1!4m5!3m4!1s0x3be7c628a4a528dd:0x7fcb5eaa0872000b!8m2!3d19.0767887!4d72.903584"
                )
              }
            >
              {" "}
              Estilo
            </p>
          </div>

          <div className="contact footer-content">
            <h2>Social</h2>
            <Icon
              icon={facebookIcon}
              style={{ pointer: "cursor" }}
              className="fb footer-content"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/PintuAirtronics-1901930600094606"
                )
              }
            />
            <Icon
              icon={linkedinIcon}
              style={{ pointer: "cursor" }}
              className="fb footer-content"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/rupesh-pintu-modi-1bb35210a"
                )
              }
            />
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Footer;
