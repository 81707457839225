import React from "react";
import "./AboutCard.css";
import Fade from "react-reveal/Fade";

const AboutCard = () => {
  return (
    <Fade left cascade>
      <div className="float-container">
        <div className="float-child-image">
          <img
            src="images/electronicsIcons.jpg"
            className="header-image-about-card"
            alt="Electronics"
          />
        </div>
        <div className="float-child-info-header">
          <h2 className="airtronic-header">We Are PINTU AIRTRONICS</h2>
          <p>
            <strong>Pintu Airtronics</strong> is one of the leading businesses
            in the AC Installation Services. Also known for All Types of AC Repair.We also provide LED TV, Refridgerator
            and much more.We have wide range of products and services to cater
            to the varied requirements of their customers.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export default AboutCard;
